import React, { useEffect } from 'react';
import '../Assets/Styles/MobileMoneyTransactionAlert.css';

type MobileTopUpAlertProps = {
    phoneNumber: string;
    timestamp: string;
    amount: string;
    onClose: () => void;
};

const MobileTopUpAlert: React.FC<MobileTopUpAlertProps> = ({
                                                               phoneNumber,
                                                               timestamp,
                                                               amount,
                                                               onClose
                                                           }) => {
    useEffect(() => {
        const timer = setTimeout(onClose, 5000);
        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className="mobile-money-transaction-alert alert-success">
            <div className="transaction-header">
                <h2>Transaction Successful</h2>
                <button onClick={onClose} className="close-btn">✕</button>
            </div>
            <div className="transaction-details">
                <p><strong>Phone Number:</strong> {phoneNumber}</p>
                <p><strong>Timestamp:</strong> {timestamp}</p>
                <p><strong>Amount:</strong> {amount}</p>
            </div>
        </div>
    );
};

export default MobileTopUpAlert;
