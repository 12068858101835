import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";
import '../../Assets/Styles/SurveyResultPage.css';
import screenOutImage from '../../Assets/Images/screenout.png';
import completeImage from '../../Assets/Images/complete.png';
import { useTranslation } from "react-i18next";
import { useLanguage } from '../../Hooks/useLanguage';
import CustomAlert from '../../Components/CustomAlert';

const TolunaResults: React.FC = () => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const HOME = `/${language}/home`;
    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
const tolunaStatus = searchParams.get('status');
    
    const [alert, setAlert] = useState<{ type: 'success' | 'error' | 'warning' | 'info'; message: string } | null>(null);
    const [status, setStatus] = useState<string | null>(null);

    const decryptData = useCallback((encryptedData: string) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_CRYPTO_KEY || 'default_key');
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            throw new Error(t('surveyResults.invalid_encryption'));
        }
    }, [t]);

    useEffect(() => {
        const processSurveyResult = async () => {

            if (tolunaStatus === 'GEO_LOCKED') {
                setStatus('TARGET_UNSUITABLE');
            } else {
                setStatus(tolunaStatus);
            }
          
        };

        processSurveyResult();
    }, [location.search, decryptData, HOME, navigate, t, tolunaStatus]);

    const getTitle = () => {
        switch (status) {
            case 'COMPLETED':
                return "Survey Completed Successfully!"
            case 'SCREEN_OUT':
                return "Sorry, You Were Screened Out"
            case 'DISQUALIFIED':
                return "Sorry, You Were Screened Out"
            case 'QUOTA_FULL':
                return "Survey Quota Reached"
            case 'TARGET_UNSUITABLE':
                return 'Target Profile Mismatch'
            case 'CLOSED':
                return "Survey Closed"
            case 'BOT_DETECTED':
                return "Geographic Restriction";
            default:
                return "Survey Status"
        }
    };


    const getMessage = () => {
        switch (status) {
            case 'COMPLETED':
                return (
                    <>
                        <p>Thank you for completing our survey. Your time and thoughtful responses are greatly appreciated.</p>
                        <p>Your reward will be credited into your account shortly.</p>
                        <p>Remember to keep your profile updated to increase your chances of qualifying for more surveys.</p>
                    </>
                );
            case 'SCREEN_OUT':
            case 'DISQUALIFIED':
                return (
                    <>
                        <p>We appreciate your effort in attempting this survey. Unfortunately, you did not meet the required criteria for this survey.</p>
                        <p>We value your time and hope to have more suitable opportunities for you in the future. Please keep an eye out for upcoming surveys.</p>
                    </>
                );
            case 'QUOTA_FULL':
                return (
                    <>
                        <p>Unfortunately, you were a little late and we have already achieved enough responses for this specific target group. Please know your time and efforts are greatly appreciated.</p>
                    </>
                );
            case 'TARGET_UNSUITABLE':
                return (
                    <>
                        <p>Thank you for your participation! Unfortunately, this survey requires a different target profile.</p>
                        <p>We appreciate your time and effort. Please keep an eye out for more surveys that match your profile.</p>
                    </>
                );
            case 'CLOSED':
                return (
                    <>
                        <p>This survey is now closed. We apologize for any inconvenience this may have caused.</p>
                        <p>Thank you for your interest and please check back for future survey opportunities.</p>
                    </>
                );
            case 'BOT_DETECTED':
                return (
                    <>
                        <p>Suspicious activity detected. Access denied.</p>
                    </>
                );
            default:
                return (
                    <>
                        <p>We have encountered an unknown status. Please try again later.</p>
                    </>
                );
        }
    };

    const getImage = () => {
        return status === 'COMPLETED' ? completeImage : screenOutImage;
    };

    return (
        <div className="main-survey-result">
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Toluna Results Page
                </title>
            </Helmet>
            {alert && <CustomAlert type={alert.type} message={alert.message} onClose={() => setAlert(null)} />}
                <div className="survey-result-container">
                    <img src={getImage()} alt={status || 'RESULT_IMG'} className="survey-result-image" />
                    <div className="survey-result-content">
                        <h1 className="survey-result-title">{getTitle()}</h1>
                        <div className="survey-result-text">{getMessage()}</div>
                        <button onClick={() => navigate(HOME)} className="bg-black hover:bg-zinc-900 rounded-lg text-white px-4 py-2 text-sm font-semibold">{t('surveyResults.survey_go_back_home')}</button>
                    </div>
                </div>
        </div>
    );
};

export default TolunaResults;