import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LoadingOverlay from '../Components/LoadingOverlay';
import CustomAlert from '../Components/CustomAlert';
import QualificationService from '../Api/Routes/Qualification/Qualification';
import '../Assets/Styles/PreScreeningPage.css';
import { useLanguage } from '../Hooks/useLanguage';
import getGeoIp from '../Middleware/GeoIp/getGeoIp';
import CryptoJS from 'crypto-js';
import { useSelector } from 'react-redux';
import { GeoIpResult, RootState } from '../Types';
import { useTranslation } from 'react-i18next';

interface Translation {
    language: string;
    translatedText?: string;
    label?: string;
}

interface AnswerOption {
    label: string;
    option: string;
    translations?: Translation[];
}

interface ScreeningQuestion {
    questionId: number;
    question: string;
    questionType: 'single_choice' | 'multiple_choice' | 'open_text';
    answerOptions: AnswerOption[];
    translations?: Translation[];
}

interface Alert {
    type: 'success' | 'error' | 'warning' | 'info';
    message: string;
}

enum Status {
    SELECTED = 'SELECTED',
    RESELECTED = 'RESELECTED',
    INVITED = 'INVITED',
    REMINDED = 'REMINDED',
    ENROLLING = 'ENROLLING',
    TARGET_SUITABLE = 'TARGET_SUITABLE',
    TARGET_UNSUITABLE = 'TARGET_UNSUITABLE',
    STARTED = 'STARTED',
    ABANDONED = 'ABANDONED',
    COMPLETED = 'COMPLETED',
    DISQUALIFIED = 'DISQUALIFIED',
    SCREEN_OUT = 'SCREEN_OUT',
    POST_DISQUALIFIED = 'POST_DISQUALIFIED',
    QUOTA_FULL = 'QUOTA_FULL',
    CLOSED = 'CLOSED',
    BOT_DETECTED = 'BOT_DETECTED',
    GEO_LOCKED = 'GEO_LOCKED'
}

const mapQuestionType = (apiType: string, options: any[]) => {
    // If options array is empty, treat it as an open text input regardless of the API type
    if (!options || options.length === 0) {
        return 'open_text';
    }

    switch (apiType.toUpperCase()) {
        case 'SINGLE_PUNCH':
            return 'single_choice';
        case 'MULTI_PUNCH':
            return 'multiple_choice';
        case 'NUMERIC_OPEN_END':
            return 'open_text';
        case 'TEXT_OPEN_END':
            return 'open_text';
        default:
            return 'open_text';
    }
};

const PreScreeningCint: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { language, setLanguage } = useLanguage();
    const { t } = useTranslation();
    const HOME = `/${language}/home`;
    const [questions, setQuestions] = useState<ScreeningQuestion[]>([]);
    const [responses, setResponses] = useState<{ [key: number]: string | string[] }>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [alert, setAlert] = useState<Alert | null>(null);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [honeypot, setHoneypot] = useState<string>('');
    const [redirecting, setRedirecting] = useState<boolean>(false);
    const loggedInGeoIpCountry = useSelector((state: RootState) => state?.country?.countryCode);

    const qualificationService = new QualificationService();

    const extractEncryptedData = useCallback((): string | null => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('data');
    }, [location.search]);

    const preserveUrlWithEncryptedData = (newLanguage: string) => {
        const encryptedData = extractEncryptedData();
        if (encryptedData) {
            navigate(`/${newLanguage}/enrollment/secure?data=${encodeURIComponent(encryptedData)}`);
        }
    };

    const redirectToExternalSite = (url: string) => {
        if (!url || url === 'undefined' || url === 'null') {
            setAlert({ 
                type: 'error', 
                message: t('preScreening.invalid_survey_link') 
            });
            return;
        }
        
        setRedirecting(true);
        setTimeout((): void => {
            window.location.href = url;
        }, 1000);
    };

    const getAnswerLabel = (question: any, precode: string): string => {
        const option = question.answerOptions.find((opt: any) => opt.option === precode);
        return option ? option.label : precode;
    };

    const validateGeoIpLocation = async (countryCode: string): Promise<boolean> => {
        try {
            const geoIpDetails: GeoIpResult = await getGeoIp();
            if (geoIpDetails?.data?.country_code) {
                const resolvedCountryCode = geoIpDetails.data.country_code.toLowerCase();
                return resolvedCountryCode === countryCode?.toLowerCase();
            }
        } catch (error) {
            return false;
        }
        return false;
    };

    const redirectToResultPage = (projectCode: string, status: Status) => {
        if (!projectCode || projectCode === 'undefined' || projectCode === 'null') {
            setAlert({ 
                type: 'error', 
                message: t('preScreening.invalid_project_code') 
            });
            navigate(HOME);
            return;
        }
        
        if (!status || !Object.values(Status).includes(status)) {
            status = Status.TARGET_UNSUITABLE; 
        }
        
        const encryptedStatus = CryptoJS.AES.encrypt(
            JSON.stringify({ projectCode, status }),
            process.env.REACT_APP_CRYPTO_KEY || 'default_key'
        ).toString();
        navigate(`/${language}/cint/survey/result?data=${encodeURIComponent(encryptedStatus)}`);
    };

    const transformQuestions = (apiQuestions: any) => {
        return apiQuestions.map((q: any) => ({
            questionId: q.QuestionID,
            question: q.QuestionText,
            questionType: mapQuestionType(q.QuestionType, q.options),
            answerOptions: q.options.map((opt: any) => ({
                label: opt.OptionText,
                option: opt.Precode,
            }))
        }));
    };

    const initiateScreeningProcess = useCallback(
        async (projectCode: string, language: string, testFlag: boolean) => {
            try {
                const data = await qualificationService.cintInitiateScreening(projectCode, language);

                if (data.success === true && data.data?.isQualified === true && data.data?.surveyLink) {
                    redirectToExternalSite(data.data.surveyLink);
                    return;
                } else if (data.success === false && data.data?.isQualified === false && data.data?.status) {
                    redirectToResultPage(projectCode, data.data.status as Status);
                    return;
                }

                if (!data.data.questions || data.data.questions.length === 0) {
                    if (data.data.surveyLink) {
                        redirectToExternalSite(data.data.surveyLink);
                    } else {
                        const status = data.data.status || Status.TARGET_UNSUITABLE;
                        redirectToResultPage(projectCode, status as Status);
                    }
                } else {
                    const mappedQuestions = transformQuestions(data.data.questions);
                    setQuestions(mappedQuestions);
                }
            } catch (error: any) {
                if (error.response?.data) {
                    const errorData = error.response.data;
                    
                    if (errorData.data?.status) {
                        redirectToResultPage(projectCode, errorData.data.status as Status);
                        return;
                    }
                }
                
                setAlert({ type: 'error', message: error.message || t('preScreening.error_message') });
                setTimeout(() => {
                    navigate(HOME, { replace: true });
                }, 5000);
            } finally {
                setLoading(false);
            }
        },
        []
    );

    useEffect(() => {
        const startProcess = async () => {
            setLoading(true);
            try {
                const encryptedData = extractEncryptedData();
                if (!encryptedData) {
                    throw new Error(t('preScreening.error_message'));
                }

                const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_CRYPTO_KEY || 'default_key');
                const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                if (!decryptedData) throw new Error(t('preScreening.error_message'));

                const { projectCode, language: decryptedLanguage, isTest } = decryptedData;

                const testFlag = isTest || false;

                if (decryptedLanguage) {
                    setLanguage(decryptedLanguage || "en");
                    preserveUrlWithEncryptedData(decryptedLanguage);
                }

                initiateScreeningProcess(projectCode, language, testFlag);
            } catch (error: any) {
                setAlert({ type: 'error', message: error.message || t('preScreening.error_message') });
                setTimeout(() => {
                    navigate(HOME, { replace: true });
                }, 3000);
            }
        };

        startProcess();
    }, []);

    const handleOptionChange = (questionId: number, option: string) => {
        setResponses(prev => ({ ...prev, [questionId]: option }));
    };

    const handleMultiOptionChange = (questionId: number, option: string) => {
        setResponses(prev => {
            const currentSelection = Array.isArray(prev[questionId]) ? prev[questionId] as string[] : [];
            const newSelection = currentSelection.includes(option)
                ? currentSelection.filter(id => id !== option)
                : [...currentSelection, option];
            return { ...prev, [questionId]: newSelection };
        });
    };

    const handleTextChange = (questionId: number, value: string) => {
        setResponses(prev => ({ ...prev, [questionId]: value }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const encryptedData = extractEncryptedData();
        if (!encryptedData) {
            setAlert({ 
                type: 'error', 
                message: t('preScreening.missing_data') 
            });
            setTimeout(() => {
                navigate(HOME, { replace: true });
            }, 3000);
            return;
        }
        
        const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_CRYPTO_KEY || 'default_key');
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const { projectCode } = decryptedData;
        
        if (!projectCode || projectCode === 'undefined' || projectCode === 'null') {
            setAlert({ 
                type: 'error', 
                message: t('preScreening.invalid_project_code') 
            });
            setTimeout(() => {
                navigate(HOME, { replace: true });
            }, 3000);
            return;
        }

        if (honeypot) {
            redirectToResultPage(projectCode, Status.BOT_DETECTED);
            return;
        }

        const incompleteAnswers = questions.some((question) => {
            const response = responses[question.questionId];
            if (question.questionType === 'single_choice' || question.questionType === 'open_text') {
                return !response;
            }
            if (question.questionType === 'multiple_choice') {
                return !response || (response as string[]).length === 0;
            }
            return false;
        });

        if (incompleteAnswers) {
            setAlert({ type: 'error', message: t('preScreening.all_questions_mandatory') });
            return;
        }

        const formattedResponses = questions.map(question => {
            const response = responses[question.questionId];
            
            if (question.questionType === 'multiple_choice') {
                const answerLabels = (response as string[]).map(precode => 
                    getAnswerLabel(question, precode)
                );
                
                return {
                    questionId: question.questionId,
                    answer: answerLabels.join(','),
                    precodes: (response as string[]).map(Number)
                };
            } else if (question.questionType === 'open_text') {
                // For open text questions (including age), just send the text value directly
                return {
                    questionId: question.questionId,
                    answer: response as string,
                    precodes: [parseInt(response as string) || 0] // Try to convert to number for age questions
                };
            } else {
                return {
                    questionId: question.questionId,
                    answer: getAnswerLabel(question, response as string),
                    precodes: [Number(response)]
                };
            }
        });

        setLoading(true);
        try {
            const response = await qualificationService.responentScreeningCintFinisher(projectCode, formattedResponses);

            if (response.success === true && response.data.isQualified === true && response.data.surveyLink) {
                redirectToExternalSite(response.data.surveyLink);
                return;
            }
            
            if (response.success === false && response.data.isQualified === false) {
                redirectToResultPage(projectCode, response.data.status as Status);
                return;
            }

            if (response.data.surveyLink) {
                redirectToExternalSite(response.data.surveyLink);
            } else {
                const status = response.data.status || Status.TARGET_UNSUITABLE;
                redirectToResultPage(projectCode, status as Status);
            }
        } catch (error: any) {
            if (error.response?.data) {
                const errorData = error.response.data;
                
                if (errorData.data?.status === 'QUOTA_FULL') {
                    redirectToResultPage(projectCode, Status.QUOTA_FULL);
                    return;
                }
                
                if (errorData.data?.status) {
                    redirectToResultPage(projectCode, errorData.data.status as Status);
                    return;
                }
                
                if (error.response?.status === 400) {
                    setError(errorData.message || t('preScreening.error_submitting'));
                    return;
                }
            }
            
            setError(error.message || t('preScreening.error_submitting'));
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setShowConfirmModal(true);
    };

    const handleConfirmCancel = () => {
        setResponses({});
        setShowConfirmModal(false);
        navigate(HOME);
    };

    if (loading || redirecting) {
        return <LoadingOverlay />;
    }

    return (
        <div className="pre-screening">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('preScreening.enrollment_title')} - AfriSight</title>
            </Helmet>
            <div className="pre-screening__content">
                {alert && <CustomAlert type={alert.type} message={alert.message} onClose={() => setAlert(null)} />}
                {!error ? (
                    <div>
                        <h1 className="pre-screening__heading">{t('preScreening.prescreening_heading')}</h1>
                        <p className="pre-screening__paragraph">{t('preScreening.prescreening_paragraph')}</p>
                    </div>
                ) : (
                    <p className="pre-screening__error-message">{error}</p>
                )}
                {!error && questions.length > 0 && (
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="honeypot"
                            value={honeypot}
                            onChange={(e) => setHoneypot(e.target.value)}
                            className="pre-screening__honeypot"
                        />
                        {questions.map((question) => (
                            <div key={question.questionId} className="pre-screening__question">
                                <h3>
                                    {question.translations?.find(
                                        (translation) => translation.language === language
                                    )?.translatedText || question.question}
                                </h3>

                                {question.questionType === 'single_choice' && (
                                    <div className="pre-screening__options">
                                        {question.answerOptions.map((option) => (
                                            <div key={option.option} className="pre-screening__custom-radio">
                                                <input
                                                    id={`${question.questionId}-${option.option}`}
                                                    type="radio"
                                                    name={`question-${question.questionId}`}
                                                    value={option.option}
                                                    checked={responses[question.questionId] === option.option}
                                                    onChange={() => handleOptionChange(question.questionId, option.option)}
                                                    className="pre-screening__hidden-input"
                                                />
                                                <label htmlFor={`${question.questionId}-${option.option}`}>
                                                    {option.translations?.find(
                                                        (translation) => translation.language === language
                                                    )?.label || option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {question.questionType === 'multiple_choice' && (
                                    <div className="pre-screening__options">
                                        {question.answerOptions.map((option) => (
                                            <div key={option.option} className="pre-screening__custom-checkbox">
                                                <input
                                                    id={`${question.questionId}-${option.option}`}
                                                    type="checkbox"
                                                    name={`question-${question.questionId}`}
                                                    value={option.option}
                                                    checked={Array.isArray(responses[question.questionId]) &&
                                                        (responses[question.questionId] as string[])?.includes(option.option)}
                                                    onChange={() => handleMultiOptionChange(question.questionId, option.option)}
                                                    className="pre-screening__hidden-input"
                                                />
                                                <label htmlFor={`${question.questionId}-${option.option}`}>
                                                    {option.translations?.find(
                                                        (translation) => translation.language === language
                                                    )?.label || option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {question.questionType === 'open_text' && (
                                    <div className="pre-screening__options">
                                        {question.questionId === 42 ? (
                                            <input
                                                type="number"
                                                id={`question-${question.questionId}`}
                                                value={responses[question.questionId] as string || ''}
                                                onChange={(e) => handleTextChange(question.questionId, e.target.value)}
                                                className="pre-screening__text-input"
                                                placeholder={t('preScreening.enter_age')}
                                                min="16"
                                                max="99"
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                id={`question-${question.questionId}`}
                                                value={responses[question.questionId] as string || ''}
                                                onChange={(e) => handleTextChange(question.questionId, e.target.value)}
                                                className="pre-screening__text-input"
                                                placeholder={t('preScreening.enter_answer')}
                                            />
                                        )}
                                    </div>
                                )}

                            </div>
                        ))}
                        <div className="pre-screening__form-buttons">
                            <button type="submit" className="pre-screening__button pre-screening__button--save">
                                {t('preScreening.submit_button')}
                            </button>
                            <button type="button" className="pre-screening__button pre-screening__button--cancel" onClick={handleCancel}>
                                {t('preScreening.cancel_button')}
                            </button>
                        </div>
                    </form>
                )}
            </div>
            {showConfirmModal && (
                <div className="pre-screening__confirm-modal">
                    <div className="pre-screening__confirm-modal-content">
                        <p className="pre-screening__paragraph">{t('preScreening.cancel_modal_message')}</p>
                        <div className="pre-screening__confirm-modal-buttons">
                            <button className="pre-screening__button--confirm" onClick={handleConfirmCancel}>
                                {t('preScreening.confirm_button')}
                            </button>
                            <button className="pre-screening__button--deny" onClick={() => setShowConfirmModal(false)}>
                                {t('preScreening.deny_button')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PreScreeningCint;
