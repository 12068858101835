import React from "react";
import Select from "react-select";
import "../Assets/Styles/LanguageSwitcher.css";
import { useLanguage } from "../Hooks/useLanguage";
import franceFlag from "../Assets/Images/France.svg";
import portugalFlag from "../Assets/Images/Portugal.svg";
import algeriaFlag from "../Assets/Images/algeria-flag.svg";
import usaFlag from "../Assets/Images/USA.svg";

const options = [
  {
    value: "en",
    label: (
      <div>
        <img src={usaFlag} alt="English" className="language-flag" />
        English
      </div>
    ),
  },
  {
    value: "fr",
    label: (
      <div>
        <img src={franceFlag} alt="Français" className="language-flag" />
        Français
      </div>
    ),
  },
  {
    value: "pt",
    label: (
      <div>
        <img src={portugalFlag} alt="Português" className="language-flag" />
        Português
      </div>
    ),
  },
    {
        value: "ar",
        label: (
            <div>
                <img src={algeriaFlag} alt="Arabic" className="language-flag" />
                Arabic
            </div>
        ),
    },
];

const LanguageSwitcher: React.FC = () => {
  const { setLanguage, language } = useLanguage();

  const handleLanguageChange = (selectedOption: any) => {
    setLanguage(selectedOption.value);
  };

  return (
    <div className="language-switcher">
      <Select
        defaultValue={options.find((option) => option.value === language)}
        options={options.filter((option) => option.value !== language)}
        onChange={handleLanguageChange}
        classNamePrefix="react-select"
      />
    </div>
  );
};

export default LanguageSwitcher;
