import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLanguage } from '../../Hooks/useLanguage';
import QualificationService from '../../Api/Routes/Qualification/Qualification';
import LoadingOverlay from '../../Components/LoadingOverlay';
import CustomAlert from '../../Components/CustomAlert';

interface TolunaResponseParams {
    SurveyID: string;
    SurveyName?: string;
    WaveID: string;
    MemberCode: string;
    completionStatus: string;
    Source: string;
    customParam?: string;
    Status: string;
    [key: string]: string | undefined;
}

interface TolunaRedirectLayoutProps {
    status: string;
}

const TolunaRedirectLayout: React.FC<TolunaRedirectLayoutProps> = ({ status }) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { language } = useLanguage();
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState<{ type: 'error' | 'success'; message: string } | null>(null);
    const qualificationService = new QualificationService();

    useEffect(() => {
        let isMounted = true;

        const updateRespondentStatus = async () => {
            try {
                // Extract and process query parameters
                const params: TolunaResponseParams = {
                    SurveyID: searchParams.get('SurveyID') || '',
                    WaveID: searchParams.get('WaveID') || '',
                    MemberCode: searchParams.get('MemberCode') || '',
                    completionStatus: searchParams.get('completionStatus') || '',
                    Source: searchParams.get('Source') || '',
                    Status: status,
                    SurveyName: searchParams.get('SurveyName') || '',
                };

                // Add any additional query parameters
                searchParams.forEach((value, key) => {
                    if (!(key in params)) {
                        params[key] = value;
                    }
                });

                const payload = {
                    SurveyID: Number(params.SurveyID) || 0,
                    WaveID: Number(params.WaveID) || 0,
                    MemberCode: params.MemberCode?.trim() || '',
                    Status: params.Status?.trim() || '',
                    SurveyName: params.SurveyName?.trim() || '',
                    Source: 'toluna',
                    completionStatus: Number(params.completionStatus) || 0,
                };

                await qualificationService.clientTolunaUpdateRespondentStatus(payload);
            } catch (error) {
                console.error('Failed to update respondent status:', error);
                setLoading(false)
                // setAlert({ type: 'error', message: t('handleRedirect.update_failed') });
            } finally {
                if (isMounted) {
                    setLoading(false);
                    navigate(`/${language}/tln/survey-result?status=${status}`, {
                        replace: true,
                    });
                }
            }
        };

        updateRespondentStatus();

        return () => {
            isMounted = false;
        };
    }, [searchParams, navigate, language, t, status]);

    if (alert) {
        return <CustomAlert type={alert.type} message={alert.message} onClose={() => setAlert(null)} />;
    }

    if (loading) {
        return <LoadingOverlay />;
    }

    return null;
};

export default TolunaRedirectLayout;
