import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DashboardLayout from '../Layouts/PageLayouts/DashboardLayout';
import '../Assets/Styles/MobileTopUp.css';
import Loading from '../Components/Loading';
import MobileTopUpHero from '../Layouts/Hero/MobileTopUpHero';
import MobileTopUpForms from '../Components/MobileTopUpForms';
import { decryptAndVerifyParam } from '../Utils/sign-routes.util';
import CustomAlert from '../Components/CustomAlert';
import MobileTopUpFormsSochitel from '../Components/MobileTopUpFormsSochitel';

const MobileTopUpv2: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();
    const { encryptedData } = useParams<{ encryptedData?: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        if (!encryptedData) {
            setError(t('mobileTopUpPage.link_expired_or_invalid'));
            setTimeout(() => {
                navigate('/');
            }, 3000);
            return;
        }

        setLoading(true);
        const data: any = decryptAndVerifyParam(encryptedData);

        if (!data || typeof data.expiration !== 'number' || Date.now() > data.expiration) {
            setError(t('mobileTopUpPage.link_expired_or_invalid'));
            setTimeout(() => {
                navigate('/');
            }, 3000);
        } else {
            setLoading(false);
        }
    }, [encryptedData, navigate, t]);

    if (loading) {
        return <Loading isLoading={loading} />;
    }

    return (
        <DashboardLayout heading={t('mobileTopUpPage.heading')}>
            <div className="main-mobile-topup-container">
                <div className="mobile-topup-content">
                    <h1 className="mobile-topup-heading">{t('mobileTopUpPage.main_heading')}</h1>
                    <p className="mobile-topup-paragraph">
                        {t('mobileTopUpPage.paragraph')}
                    </p>
                    <MobileTopUpHero />
                    {error && <CustomAlert type="error" message={error} onClose={() => setError(null)} />}
                    {!error && <MobileTopUpFormsSochitel />}
                </div>
            </div>
        </DashboardLayout>
    );
};

export default MobileTopUpv2;
