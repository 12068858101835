import React, { useEffect, useState } from "react";
import '../../Assets/Styles/MobileTopUpHero.css';
import mobileTopUp from '../../Assets/Images/mobile-1.svg';
import ProfileService from "../../Api/Routes/Profile/ProfileService";
import { useTranslation } from "react-i18next";
import PayoutLimitsService from "../../Api/Routes/PayoutLimits/PayoutLimitsServices";
import {useSelector} from "react-redux";
import {RootState} from "../../Types";

const MobileTopUpHero: React.FC = () => {
    const [rewardBalance, setRewardBalance] = useState<number>(0);
    const [max, setMax] = useState<number>(0);
    const [min, setMin] = useState<number>(0);
    const countryCode = useSelector((state: RootState) => state?.profile.profile?.countryCode);
    const payoutLimits = useSelector((state: RootState) => state.payoutlimit.payoutLimits);
    const reloadlyLimit = payoutLimits.data.find((limit: { payoutMethod: string; }) => limit.payoutMethod === "RELOADLY");
    const minTransaction = reloadlyLimit?.minTransaction.toFixed(2) ?? 3;
    const maxTransaction = reloadlyLimit?.maxTransaction.toFixed(2) ?? 10;

    const { t } = useTranslation();

    useEffect(() => {
        const fetchRewardBalance = async () => {
            const profileService = new ProfileService();
            try {
                const response = await profileService.getUserRewardBalance();


                if (response.success) {
                    setRewardBalance(response.data.rewardBalance);
                } else {
                    setRewardBalance(0.00);
                }
            } catch (error: any) {
                setRewardBalance(0.00);
                setMax(0.00)
                setMin(0.00)
            }
        };
        console.log(payoutLimits)


        fetchRewardBalance();
    }, []);

    return (
        <div className="mobile-topup-hero">
            <div className="mobile-topup-hero-content">
                <div className="mobile-topup-hero-text">
                    <h1 className="mobile-topup-hero-heading">{t("mobileTopUpHero.heading")}</h1>
                    <p className="mobile-topup-account-balance">{t("mobileTopUpHero.account_balance", { balance: rewardBalance.toFixed(2) })}</p>
                    <p className="mobile-topup-minimum-withdrawal">{t("mobileTopUpHero.minimum_withdrawal")} {minTransaction}</p>
                </div>
                <div className="mobile-topup-hero-image">
                    <img src={mobileTopUp} alt="Mobile Top Up" />
                </div>
            </div>
        </div>
    );
};

export default MobileTopUpHero;
