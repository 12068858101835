import React from 'react'
import Spinner from '../Spinner/Spinner'

interface Props{
    className?:string
    height?:string
}

function CustomLoading({className, height}:Props) {
  return (
    <div className={`${className} ${height ? height : "min-h-[90vh]" } grid items-center content-center justify-center`}>
                <Spinner/>
            </div>
  )
}

export default CustomLoading