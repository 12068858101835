// NetworkStatus.tsx
import useOnlineStatus from '../../Hooks/useOnlineStatus';

const NetworkStatus = () => {
  const isOnline = useOnlineStatus();

  return (
    <div
      className={`
        flex items-center gap-2 
        px-4 py-2 rounded-full
        transition-all duration-300 ease-in-out
        shadow-lg
        ${isOnline 
          ? 'bg-green-500 text-white' 
          : 'bg-red-500 text-white'
        }
      `}
    >
      <span 
        className={`
          h-2 w-2 rounded-full 
          animate-pulse
          ${isOnline ? 'bg-white' : 'bg-white'}
        `}
      />
      <span className="text-xs font-medium">
        {isOnline ? 'Online' : 'Offline'}
      </span>
    </div>
  );
};

export default NetworkStatus;