import React, { useEffect, useState } from "react";
import "../../Assets/Styles/TopBar.css";
import franceFlag from "../../Assets/Images/France.svg";
import portugalFlag from "../../Assets/Images/Portugal.svg";
import usaFlag from "../../Assets/Images/USA.svg";
import dropdownArrow from "../../Assets/Images/chevron-down.svg";
import userIcon from "../../Assets/Images/user.svg";
import lockIcon from "../../Assets/Images/lock.svg";
import logoutIcon from "../../Assets/Images/log-out.svg";
import algeriaFlag from "../../Assets/Images/algeria-flag.svg"
import maleAvatar from "../../Assets/Images/male.png";
import femaleAvatar from "../../Assets/Images/female.png";
import unknownAvatar from "../../Assets/Images/neutral.png";
import { Link } from "react-router-dom";
import NotificationModal from "../Notifications/NotificationModal";
import { subHours, subDays, formatISO } from "date-fns";
import { useLanguage } from "../../Hooks/useLanguage";
import { useDispatch, useSelector } from "react-redux";
import {
  profileError,
  profileLoaded,
  profileLoading,
  selectProfile,
} from "../../Store/slices/profile.slice";
import ProfileService from "../../Api/Routes/Profile/ProfileService";
import { RootState } from "../../Types";
import useLogout from "../../Hooks/useLogout";
import { IoMdMenu } from "react-icons/io";
import Sidebar from "../SideBar/SideBar";
import NetworkStatus from "../../Components/network-status/NetworkStatus";

interface Notification {
  id: string;
  message: string;
  date: string;
  actionText: string;
  actionHandler: () => void;
  category: "info" | "failed" | "success";
}

interface TopBarProps {
  isNavOpen?: boolean;
  setIsNavOpen?: any;
}

const TopBar = ({ isNavOpen, setIsNavOpen }: TopBarProps) => {
  const [isAccountModalOpen, setAccountModalOpen] = useState(false);
  const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState<string | null>(null);
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const { language, setLanguage } = useLanguage();
  const PROFILE = `/${language}/profile`;
  const SECURITY = `/${language}/security`;
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => selectProfile(state));
  const logout = useLogout();

  const toggleAccountModal = () => setAccountModalOpen(!isAccountModalOpen);
  const toggleNotificationModal = () =>
    setNotificationModalOpen(!isNotificationModalOpen);
  const toggleLanguageDropdown = () =>
    setLanguageDropdownOpen(!isLanguageDropdownOpen);

  useEffect(() => {
    const fetchProfile = async () => {
      if (profile) {
        setGender(profile.gender);
        return;
      }
      setLoading(true);
      dispatch(profileLoading());
      const profileService = new ProfileService();
      try {
        const response = await profileService.getUserProfile();
        if (response.success) {
          dispatch(profileLoaded(response.data.personDetails));
          setGender(response.data.personDetails.gender);
        } else {
          dispatch(
            profileError(
              response.message ||
                "An unexpected issue occurred. Please try again later."
            )
          );
        }
      } catch (error: any) {
        dispatch(profileError(error.message));
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [dispatch, profile]);

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        // Simulating a fetch call with typed Promise
        const fetchedNotifications = await new Promise<Notification[]>(
          (resolve) =>
            setTimeout(
              () =>
                resolve([
                  {
                    id: "1",
                    message: "New survey for you.",
                    date: formatISO(new Date()),
                    actionText: "View",
                    actionHandler: () => {},
                    category: "info",
                  },
                  {
                    id: "2",
                    message: "Your payout was approved.",
                    date: formatISO(subHours(new Date(), 1)),
                    actionText: "View",
                    actionHandler: () => {},
                    category: "success",
                  },
                  {
                    id: "3",
                    message: "Your payout was rejected.",
                    date: formatISO(subDays(new Date(), 1)),
                    actionText: "View",
                    actionHandler: () => {},
                    category: "failed",
                  },
                  {
                    id: "4",
                    message: "Server downtime scheduled.",
                    date: formatISO(subDays(new Date(), 2)),
                    actionText: "View",
                    actionHandler: () => {},
                    category: "info",
                  },
                  {
                    id: "5",
                    message: "Server downtime scheduled.",
                    date: formatISO(subDays(new Date(), 2)),
                    actionText: "View",
                    actionHandler: () => {},
                    category: "info",
                  },
                  {
                    id: "6",
                    message: "Server downtime scheduled.",
                    date: formatISO(subDays(new Date(), 2)),
                    actionText: "View",
                    actionHandler: () => {},
                    category: "info",
                  },
                  {
                    id: "7",
                    message: "Server downtime scheduled.",
                    date: formatISO(subDays(new Date(), 2)),
                    actionText: "View",
                    actionHandler: () => {},
                    category: "info",
                  },
                ]),
              1000
            )
        );
        setNotifications(fetchedNotifications);
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      }
      setLoading(false);
    };

    fetchNotifications();
  }, []);

  const capitalizeAndTrim = (text: any) => {
    if (typeof text !== "string" || text.trim().length === 0) {
      return "";
    }
    const words = text.split(" ");
    const firstWord = words[0];
    return firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
  };

  const getAvatar = () => {
    if (gender === "male") {
      return maleAvatar;
    } else if (gender === "female") {
      return femaleAvatar;
    } else {
      return unknownAvatar;
    }
  };

  const handleLanguageChange = (lang: string) => {
    setLanguage(lang);
    setLanguageDropdownOpen(false);
  };

  return (
    <nav className="horizontal-nav bg-white">
      <div className="nav-placeholder">
        {isNavOpen ? (
          <span onClick={() => setIsNavOpen(!isNavOpen)}>X</span>
        ) : (
          <span onClick={() => setIsNavOpen(!isNavOpen)}>
            <IoMdMenu height={24} width={24} />
          </span>
        )}
      </div>
     

      {isNavOpen && <Sidebar setIsNavOpen={setIsNavOpen} />}
      <NetworkStatus />
      <div className="account-section">
        <div
          className="top-bar-language-switcher"
          onClick={toggleLanguageDropdown}
        >
          {language === "en" && (
            <img src={usaFlag} alt="English" className="language-flag" />
          )}
          {language === "fr" && (
            <img src={franceFlag} alt="Français" className="language-flag" />
          )}
          {language === "pt" && (
            <img src={portugalFlag} alt="Português" className="language-flag" />
          )}
          {language === "ar" && (
            <img src={algeriaFlag} alt="Arabic" className="language-flag" />
          )}
          <span>{language.toUpperCase()}</span>
          <img
            src={dropdownArrow}
            alt="Language dropdown"
            className={`language-dropdown-icon ${
              isLanguageDropdownOpen ? "rotate" : ""
            }`}
          />
          {isLanguageDropdownOpen && (
              <div className="language-dropdown">
                <div
                    className="language-option"
                    onClick={() => handleLanguageChange("en")}
                >
                  <img src={usaFlag} alt="English" className="language-flag"/>
                  <span>EN</span>
                </div>
                <div
                    className="language-option"
                    onClick={() => handleLanguageChange("fr")}
                >
                  <img
                      src={franceFlag}
                      alt="Français"
                      className="language-flag"
                  />
                  <span>FR</span>
                </div>
                <div
                    className="language-option"
                    onClick={() => handleLanguageChange("pt")}
                >
                  <img
                      src={portugalFlag}
                      alt="Português"
                      className="language-flag"
                  />
                  <span>PT</span>
                </div>
                <div
                    className="language-option"
                    onClick={() => handleLanguageChange("ar")}
                >
                  <img
                      src={algeriaFlag}
                      alt="Arabic"
                      className="language-flag"
                  />
                  <span>AR</span>
                </div>
              </div>
          )}
        </div>
        {/*<div className="notification-bell" onClick={toggleNotificationModal}>*/}
        {/*  <img src={bellIcon} alt="Notifications" />*/}
        {/*  {notifications.length > 0 && (*/}
        {/*    <div className="notification-count">{notifications.length}</div>*/}
        {/*  )}*/}
        {/*</div>*/}
       
        <img src={getAvatar()} alt="User Avatar" className="user-avatar" />
        <div className="account-info" onClick={toggleAccountModal}>
          <span className="username">
          {
            profile && profile.firstName && profile.firstName.toLowerCase() !== "null" && profile.lastName && profile.lastName.toLowerCase() !== "null"
                ? `${capitalizeAndTrim(profile.firstName)} ${capitalizeAndTrim(profile.lastName)}`
                : "Panelist"
          }
          </span>
          <img
            src={dropdownArrow}
            alt="Account dropdown"
            className={`account-dropdown-icon ${
              isAccountModalOpen ? "rotate" : ""
            }`}
          />
        </div>
        {isAccountModalOpen && (
          <div className="account-modal">
            <ul className="modal-items">
              <li className="modal-item">
                <img src={userIcon} alt="My Profile" />
                <Link to={PROFILE} className="top-bar-links">
                  My Profile
                </Link>
              </li>
              <li className="modal-item">
                <img src={lockIcon} alt="Security" />
                <Link to={SECURITY} className="top-bar-links">
                  Security
                </Link>
              </li>
              <li className="modal-item" onClick={logout}>
                <img src={logoutIcon} alt="Log out" />
                Log out
              </li>
            </ul>
          </div>
        )}
        {isNotificationModalOpen && (
          <NotificationModal
            notifications={notifications}
            loading={loading}
            error=""
            isOpen={isNotificationModalOpen}
            onClose={toggleNotificationModal}
          />
        )}
      </div>
    </nav>
  );
};

export default TopBar;
