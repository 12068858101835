import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../Types';
import countryToCurrency, { Currencies, Countries } from 'country-to-currency';
import '../Assets/Styles/MobileTopUpForms.css';
import CustomAlert from "./CustomAlert";
import LoadingOverlay from './LoadingOverlay';
import MobileTopUpAlert from "./MobileTopUpAlert";
import PayoutService from '../Api/Routes/Payouts/PayoutsService';
import africanCountries from '../Constants/CountryArray/africancountries.json';

type MobileTopUpDetails = {
    phoneNumber: string;
    provider: string;
    countryCode: string;
};

const MobileTopUpFormsSochitel: React.FC = () => {
    const { t } = useTranslation();
    const [amountUSD, setAmountUSD] = useState<string>('');
    const [convertedAmount, setConvertedAmount] = useState<string>('');
    const [amountError, setAmountError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [providerVerified, setProviderVerified] = useState<boolean>(false);
    const [topUpDetails, setTopUpDetails] = useState<MobileTopUpDetails>({ phoneNumber: '', provider: '', countryCode: '' });
    const [alert, setAlert] = useState<{ type: 'success' | 'error' | 'warning' | 'info'; message: string } | null>(null);
    const [transactionAlert, setTransactionAlert] = useState<{
        show: boolean;
        phoneNumber: string;
        timestamp: string;
        amount: string;
        status: 'success' | 'error';
    } | null>(null);

    const [cooldownTime, setCooldownTime] = useState<number>(0);
    const [remainingTime, setRemainingTime] = useState<number>(0);

    const [operatorName, setOperatorName] = useState<string>('');
    const [operatorLogo, setOperatorLogo] = useState<string>('');
    const countryCode = useSelector((state: RootState) => state.profile?.profile?.countryCode);
    const phoneNumberFromProfile = useSelector((state: RootState) => state?.profile?.profile?.phoneNumber);
    const [operatorData, setOperatorData] = useState<any>(null);
    const [userCurrency, setUserCurrency] = useState<string>('USD');
    const payoutLimits = useSelector((state: RootState) => state?.payoutlimit?.payoutLimits);
    const reloadlyLimit = payoutLimits?.data?.find((limit: { payoutMethod: string; }) => limit.payoutMethod === "RELOADLY");
    const minTransaction = reloadlyLimit ? parseFloat(reloadlyLimit?.minTransaction) : 3.00;
    const maxTransaction = reloadlyLimit ? parseFloat(reloadlyLimit?.maxTransaction) : 10.00;

    const COOLDOWN_DURATION = 10 * 60 * 1000;

    useEffect(() => {
        if (phoneNumberFromProfile) {
            const extractedCountryCode = Object.entries(africanCountries).find(([_, dialCode]) =>
                phoneNumberFromProfile.startsWith(dialCode)
            );
            if (extractedCountryCode) {
                const [countryCode, dialCode] = extractedCountryCode;
                const formattedPhoneNumber = phoneNumberFromProfile.replace(dialCode, '');
                setTopUpDetails({ phoneNumber: formattedPhoneNumber, provider: '', countryCode });
            } else {
                setTopUpDetails(prevDetails => ({ ...prevDetails, phoneNumber: phoneNumberFromProfile }));
            }
        } else if (countryCode) {
            setTopUpDetails(prevDetails => ({ ...prevDetails, countryCode }));
        }
    }, [phoneNumberFromProfile, countryCode]);

    useEffect(() => {
        if (amountUSD) {
            calculateConvertedAmount(amountUSD);
        }
    }, [amountUSD]);

    useEffect(() => {
        if(countryCode?.toLowerCase() !== 'zw'){
            const updatedCurrency = countryToCurrency[topUpDetails.countryCode as Countries] as Currencies;
            setUserCurrency(updatedCurrency || 'USD');
        }

        setUserCurrency('USD');

    }, [topUpDetails.countryCode]);

    useEffect(() => {
        const lastTransactionTime = localStorage.getItem('lastTransactionTime');
        if (lastTransactionTime) {
            const elapsedTime = Date.now() - parseInt(lastTransactionTime);
            const remainingCooldown = COOLDOWN_DURATION - elapsedTime;
            if (remainingCooldown > 0) {
                setCooldownTime(remainingCooldown);
                setRemainingTime(remainingCooldown);
            }
        }
    }, []);

    useEffect(() => {
        if (cooldownTime > 0) {
            const interval = setInterval(() => {
                setRemainingTime(prevTime => {
                    if (prevTime <= 1000) {
                        clearInterval(interval);
                        return 0;
                    }
                    return prevTime - 1000;
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [cooldownTime]);

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const amount = event.target.value.trim();
        validateAmount(amount);
        setAmountUSD(amount);
    };

    const calculateConvertedAmount = (amount: string) => {
        const validAmount = parseFloat(amount);
        if (isNaN(validAmount)) {
            setConvertedAmount('');
            return;
        }

        if (operatorData?.data?.fx?.rate) {
            const localAmount = (validAmount * operatorData.data.fx.rate).toFixed(2);
            setConvertedAmount(`${localAmount} ${userCurrency}`);
        } else {
            setConvertedAmount('');
        }
    };

    const validateAmount = (amount: string) => {
        if (!amount) {
            setAmountError('');
            setConvertedAmount('');
            return;
        }
        const numAmount = parseFloat(amount);
        if (isNaN(numAmount) || numAmount < minTransaction || numAmount > maxTransaction) {
            setAmountError(`${t('mobileTopUp.amount_error',{ minAmount: minTransaction.toFixed(2) ,maxAmount:maxTransaction.toFixed(2)})} `);
        } else {
            setAmountError('');
        }
    };

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let phoneNumber = event.target.value.trim();

        const matchedCountry = Object.entries(africanCountries).find(([_, dialCode]) =>
            phoneNumber.startsWith(dialCode)
        );
        if (matchedCountry) {
            const [countryCode, dialCode] = matchedCountry;
            phoneNumber = phoneNumber.replace(dialCode, '');
            setTopUpDetails({ phoneNumber, provider: '', countryCode });
        } else {
            setTopUpDetails(prevDetails => ({ ...prevDetails, phoneNumber }));
        }
    };

    const handleCountryCodeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setTopUpDetails({ ...topUpDetails, countryCode: event.target.value });
    };

    const clearPhoneNumber = () => {
        setTopUpDetails({ phoneNumber: '', provider: '', countryCode: '' });
        setProviderVerified(false);
    };

    const formatPhoneNumber = (phoneNumber: string, countryCode: string): string => {
        const countryDialCode = africanCountries[countryCode as keyof typeof africanCountries];
        if (phoneNumber.startsWith(countryDialCode)) {
            return phoneNumber.replace(countryDialCode, '');
        }
        return phoneNumber;
    };

    const verifyProvider = async () => {
        setLoading(true);
        try {
            const reloadly = new PayoutService();
            const response = await reloadly.getSochitelOperators({
                countryCode: topUpDetails.countryCode,
                productType: "1"
            });

            const fullPhoneNumber = `${africanCountries[topUpDetails.countryCode as keyof typeof africanCountries]}${topUpDetails.phoneNumber}`.replace('+', '');
            
            console.log('Full Phone Number:', fullPhoneNumber);

            const matchingOperator = response.data.operators.find((operator: any) => {
                return operator.prefixes.some((prefix: string) => {
                    const matches = fullPhoneNumber.startsWith(prefix);
                    console.log(`Checking prefix ${prefix} against ${fullPhoneNumber}: ${matches}`);
                    return matches;
                });
            });

            if (!matchingOperator) {
                throw new Error('No matching operator found for this phone number');
            }

            console.log('Matched Operator:', matchingOperator);

            setOperatorData({
                data: {
                    operatorId: matchingOperator.id,
                    name: matchingOperator.name,
                    country: {
                        isoName: matchingOperator.country.code
                    },
                    destinationCurrencyCode: matchingOperator.currency,
                    fx: {
                        rate: 1
                    },
                    logoUrls: [`/images/operators/${matchingOperator.name.toLowerCase().replace(/\s+/g, '-')}.png`]
                }
            });

            setTopUpDetails(prevDetails => ({ ...prevDetails, provider: matchingOperator.name }));
            setOperatorName(matchingOperator.name);
            setOperatorLogo(`/images/operators/${matchingOperator.name.toLowerCase().replace(/\s+/g, '-')}.png`);
            setProviderVerified(true);
            setLoading(false);
        } catch (error: any) {
            console.error('Verification Error:', error);
            setAlert({ type: 'error', message: error.message });
            setLoading(false);
        }
    };

    const requestTopUp = async () => {
        if (remainingTime > 0) {
            setAlert({ type: 'error', message: t('mobileTopUp.cooldown_message') });
            return;
        }

        setLoading(true);
        try {
            const formattedPhoneNumber = formatPhoneNumber(topUpDetails.phoneNumber, topUpDetails.countryCode);
            const combinedPhoneNumber = `${africanCountries[topUpDetails.countryCode as keyof typeof africanCountries]}${formattedPhoneNumber}`;

            const makeTopup = new PayoutService();
            await makeTopup.createUnifiedPaymentSochitel({
                countryCode: operatorData.data.country.isoName,
                number: combinedPhoneNumber,
                amount: parseFloat(amountUSD),
                operatorId: String(operatorData.data.operatorId),
                currency: operatorData.data.destinationCurrencyCode,
            });

            localStorage.setItem('lastTransactionTime', Date.now().toString());

            setLoading(false);
            setTransactionAlert({
                show: true,
                phoneNumber: combinedPhoneNumber,
                timestamp: new Date().toLocaleString(),
                amount: `$${amountUSD}`,
                status: 'success',
            });

            setAmountUSD('');
            setConvertedAmount('');
            setTopUpDetails({ phoneNumber: '', provider: '', countryCode: '' });
            setProviderVerified(false);
            setCooldownTime(COOLDOWN_DURATION);
            setRemainingTime(COOLDOWN_DURATION);
        } catch (error: any) {
            setAlert({ type: 'error', message: error.message });
            setLoading(false);
        }
    };

    const formatTime = (milliseconds: number) => {
        const minutes = Math.floor(milliseconds / 60000);
        const seconds = Math.floor((milliseconds % 60000) / 1000);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <>
            {alert && <CustomAlert type={alert.type} message={alert.message} onClose={() => setAlert(null)} />}
            {transactionAlert && transactionAlert.show && (
                <MobileTopUpAlert
                    phoneNumber={transactionAlert.phoneNumber}
                    timestamp={transactionAlert.timestamp}
                    amount={transactionAlert.amount}
                    onClose={() => setTransactionAlert(null)}
                />
            )}
            <div className="mobile-top-up-form">
                <div className="mobile-top-up-form__content">
                    {!providerVerified ? (
                        <div className="mobile-top-up-form__step">
                            <h2>{t('mobileTopUp.heading')}</h2>
                            <p>{t('mobileTopUp.description')}</p>
                            <div className="mobile-top-up-form__input-group">
                                <label htmlFor="phoneNumber">{t('mobileTopUp.phone_number_label')}</label>
                                <div className="mobile-top-up-form__input-wrapper">
                                    <select
                                        id="countryCode"
                                        name="countryCode"
                                        value={topUpDetails.countryCode}
                                        onChange={handleCountryCodeChange}
                                        disabled={loading}
                                        className="mobile-top-up-form__country-code-select"
                                    >
                                        {Object.entries(africanCountries).map(([code, dialCode]) => (
                                            <option key={code} value={code}>
                                                {dialCode} ({code})
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        type="text"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={topUpDetails.phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                        placeholder={t('mobileTopUp.phone_number_placeholder')}
                                        className={!/^\d{7,14}$/.test(topUpDetails.phoneNumber) ? "mobile-top-up-form__input-error" : ""}
                                        disabled={loading}
                                    />
                                    {topUpDetails.phoneNumber && (
                                        <button className="mobile-top-up-form__clear-button" onClick={clearPhoneNumber}>
                                            {t('mobileTopUp.clear_button')}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="mobile-top-up-form__buttons">
                                <button className="mobile-top-up-form__button" onClick={verifyProvider}
                                        disabled={loading || !/^\d{7,14}$/.test(topUpDetails.phoneNumber)}>
                                    {t('mobileTopUp.next_step_button')}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="mobile-top-up-form__operator-info">
                                <h3 className="mobile-top-up-form__operator-name">{operatorName}</h3>
                            </div>

                            <div className="mobile-top-up-form__step">
                                <div className="mobile-top-up-form__inputs-container">
                                    <div className="mobile-top-up-form__amount-input-group">
                                        <label htmlFor="amountUSD">{t('mobileTopUp.amount_to_topup_label')}</label>
                                        <input
                                            type="number"
                                            id="amountUSD"
                                            name="amountUSD"
                                            value={amountUSD}
                                            onChange={handleAmountChange}
                                            className={amountError ? "mobile-top-up-form__input-error" : ""}
                                            placeholder={t('mobileTopUp.amount_usd_placeholder')}
                                            min={minTransaction}
                                            max={maxTransaction}
                                            disabled={loading}
                                        />
                                        {amountError && <div className="mobile-top-up-form__error-message">{amountError}</div>}
                                    </div>

                                    <div className="mobile-top-up-form__converted-amount-group">
                                        <label htmlFor="convertedAmount">{t('mobileTopUp.converted_amount_label')}</label>
                                        <input
                                            type="text"
                                            id="convertedAmount"
                                            name="convertedAmount"
                                            value={convertedAmount}
                                            disabled
                                            placeholder={userCurrency}
                                        />
                                    </div>
                                </div>

                                <div className="mobile-top-up-form__conversion-info">
                                    <p className="mobile-top-up-form__minimum-withdrawal">{t('mobileTopUp.minimum_withdrawal', { minAmount: minTransaction.toFixed(2) })}<span style={{ color: "#ff4b4b" }}>*</span></p>
                                    <button className="mobile-top-up-form__request-payout-btn" onClick={requestTopUp} disabled={!amountUSD || parseFloat(amountUSD) < minTransaction || remainingTime > 0}>
                                        {remainingTime > 0 ? `${t('mobileTopUp.cooldown_label')}: ${formatTime(remainingTime)}` : t('mobileTopUp.request_topup_button')}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {loading && <LoadingOverlay />}
            </div>
        </>
    );
};

export default MobileTopUpFormsSochitel;
