import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../Hooks/useLanguage';
import { Helmet } from 'react-helmet';
import HeaderComponent from '../Layouts/Header/Header';
import LanguageSwitcher from '../Components/LanguageSwitcher';

function VerifySuccess() {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          navigate(`/${language}/home`);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate, language]);

  return (
    <>
      <div className='register-page-container min-h-screen bg-gray-50'>
        <div className="register-page-header-language-container">
          <HeaderComponent />
          <LanguageSwitcher />
        </div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Verification Success | AfriSight</title>
        </Helmet>
        <div className="flex justify-center items-center min-h-[calc(100vh-200px)]">
          <div className="text-center p-10 bg-white rounded-xl shadow-lg max-w-lg w-full mx-4 transform transition-all hover:scale-105">
            <div className="w-20 h-20 mx-auto mb-8 rounded-full bg-green-100 flex items-center justify-center">
              <svg className="w-12 h-12 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            <h1 className="text-green-600 mb-4 text-3xl font-bold">Email Verified Successfully!</h1>
            <p className="text-gray-700 text-lg mb-4">You will be redirected to the dashboard in</p>
            <div className="text-4xl font-bold text-green-600 mb-4">{countdown}</div>
            <p className="text-gray-500">seconds...</p>
          </div>
        </div>
s      </div>
    </>
  );
}

export default VerifySuccess;