import React, { useEffect } from 'react';

const ZohoSalesIQWidget: React.FC = () => {
  useEffect(() => {
    // Add Zoho SalesIQ global object
    const zohoScript = document.createElement('script');
    zohoScript.innerHTML = `
      window.$zoho=window.$zoho || {};
      $zoho.salesiq=$zoho.salesiq||{ready:function(){}};
    `;
    document.body.appendChild(zohoScript);

    // Add the SalesIQ widget script
    const salesIqScript = document.createElement('script');
    salesIqScript.id = 'zsiqscript';
    salesIqScript.src = `https://salesiq.zohopublic.eu/widget?wc=${process.env.REACT_APP_ZOHO_KEY}`;
    salesIqScript.defer = true;
    document.body.appendChild(salesIqScript);

    // Cleanup script on component unmount
    return () => {
      zohoScript.remove();
      salesIqScript.remove();
    };
  }, []);

  return null; // This component doesn't render any JSX
};

export default ZohoSalesIQWidget;
