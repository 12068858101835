import React from 'react';
import '../Assets/Styles/Loading.css';

type LoadingProps = {
    isLoading: boolean;
};

const Loading: React.FC<LoadingProps> = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="loading-overlay">
            <div className="loading-spinner"></div>
        </div>
    );
};

export default Loading;