// hooks/useUserActivity.ts
import { useState, useEffect } from 'react';
import UserService from '../Api/Routes/Users/UserService';

const INACTIVE_TIMEOUT = 6 * 60 * 1000; // 6 minutes
const STATUS_UPDATE_INTERVAL = 5 * 60 * 1000; // 5 minutes

export const useUserActivity = () => {
  const [isActive, setIsActive] = useState(true);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const userService = new UserService()

  const sendActivityStatus = async () => {
    try {
      if (isActive) {
        await userService.sendUserActivity()
      }
    } catch (error) {
      console.error('Failed to update activity status:', error);
    }
  };

  useEffect(() => {
    const updateActivity = () => {
      setIsActive(true);
      setLastActivity(Date.now());
    };

    const checkInactivity = () => {
      if (Date.now() - lastActivity > INACTIVE_TIMEOUT) {
        setIsActive(false);
      }
    };

    // Event listeners for user activity
    const events = ['mousedown', 'keydown', 'mousemove', 'wheel', 'touchstart'];
    events.forEach(event => document.addEventListener(event, updateActivity));

    // Check inactivity every minute
    const inactivityInterval = setInterval(checkInactivity, 60000);
    
    // Send status update every 2 minutes
    const statusUpdateInterval = setInterval(sendActivityStatus, STATUS_UPDATE_INTERVAL);

    return () => {
      events.forEach(event => document.removeEventListener(event, updateActivity));
      clearInterval(inactivityInterval);
      clearInterval(statusUpdateInterval);
    };
  }, [lastActivity, isActive]);

  return isActive;
};

export default useUserActivity