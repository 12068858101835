import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || 'your-secret-key';
const SIGNING_KEY = process.env.REACT_APP_SIGNING_KEY || 'your-signing-key';

export const encryptAndSignParam = (data: object): string => {
    const dataString = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(dataString, SECRET_KEY).toString();
    const signature = CryptoJS.HmacSHA256(encryptedData, SIGNING_KEY).toString();

    const combinedData = `${encryptedData}.${signature}`;
    return encodeURIComponent(combinedData);
};

export const decryptAndVerifyParam = (encryptedParam: string): object | null => {
    const decodedParam = decodeURIComponent(encryptedParam);
    const [encryptedData, signature] = decodedParam.split('.');

    if (!encryptedData || !signature) {
        return null;
    }

    const validSignature = CryptoJS.HmacSHA256(encryptedData, SIGNING_KEY).toString();
    if (validSignature !== signature) {
        return null;
    }

    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
};
