import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DashboardLayout from '../../Layouts/PageLayouts/DashboardLayout';
import UserDemographicsChart from '../Admin/Components/UserDemographicsChart';
import UserGrowthChart from '../Admin/Components/UserGrowthChart';
import TopEarnersTable from '../Admin/Components/TopEarnersTable';
import { FaUsers, FaChartLine, FaMoneyBillWave, FaCheckCircle } from 'react-icons/fa';
import UserService from '../../Api/Routes/Users/UserService';
import UserParticipationChart from './Components/UserParticipationChart';
import UserGrowthComparisonChart from './Components/UserGrowthComparisonChart';
import countries from '../../Constants/CountryArray/countries.json'; // Import the countries JSON


function ManagerAnalytics() {
    const [timeframe, setTimeframe] = useState<'month' | 'year'>('month');
    const [country, setCountry] = useState<string>(''); 
    const userService = useMemo(() => new UserService(), []);

    // Loading states for each section
    const [loadingGrowth, setLoadingGrowth] = useState(true);
    const [loadingDemographics, setLoadingDemographics] = useState(true);
    const [loadingTopEarners, setLoadingTopEarners] = useState(true);
    const [loadingParticipation, setLoadingParticipation] = useState(true);
    const [loadingAnalytics, setLoadingAnalytics] = useState(true);
    const [loadingYearlyMonthly, setLoadingYearlyMonthly] = useState(true); 

    const [userGrowthData, setUserGrowthData] = useState<any>(null);
    const [analyticsData, setAnalyticsData] = useState({
        totalUsers: 0,
        growthRate: 0,
        totalEarnings: 0,
        completionRate: 0,
        completionRateChange:0
    });
    const [demographicsData, setDemographicsData] = useState<any>(null);
    const [topEarners, setTopEarners] = useState<any>({
        balance: [],
        payouts: [],
        rewards: []
    });
    const [userParticipationData, setUserParticipationData] = useState<any>(null);
    const [yearlyMonthlyData, setYearlyMonthlyData] = useState<any>(null);

    
    const fetchUserParticipationData = useCallback(async () => {
        setLoadingParticipation(true);
        try {
            const response = await userService.getUserParticipationData(timeframe);
            setUserParticipationData(response);
            const sortedData = [...response.participationData].sort((a, b) => 
                new Date(b.date).getTime() - new Date(a.date).getTime()
            );
            
            const currentMonth = sortedData[0];
            const previousMonth = sortedData[1];
            
            // Calculate the change in completion rate
            const completionRateChange = currentMonth && previousMonth
                ? (currentMonth.completionRate - previousMonth.completionRate).toFixed(2)
                : '0.00';
    
            setUserParticipationData(response.participationData);
            setAnalyticsData(prev => ({
                ...prev,
                completionRate: currentMonth?.completionRate || 0,
                completionRateChange: parseFloat(completionRateChange)
            }));
        } catch (error) {
            console.error('Error fetching user participation data:', error);
        } finally {
            setLoadingParticipation(false);
        }
    }, [timeframe, userService]);

    const fetchYearlyMonthlyData = useCallback(async () => {
        setLoadingYearlyMonthly(true);
        try {
            const response = await userService.getYearlyMonthlyData({country});
            setYearlyMonthlyData(response.data);
        } catch (error) {
            console.error('Error fetching yearly monthly data:', error);
        } finally {
            setLoadingYearlyMonthly(false);
        }
    }, [userService, country]);

    const fetchUserGrowthData = useCallback(async () => {
        setLoadingGrowth(true);
        setLoadingAnalytics(true);
        try {
            const response = await userService.getUserGrowthData({ timeframe, country }); // Ensure country is passed here
    
            if (response) {
                // Filter data starting from October 2024
                const filteredData = response.data.data.filter((item:any) => {
                    const itemDate = new Date(item.date);
                    const filterStartDate = new Date('2024-10-01');
                    return itemDate >= filterStartDate;
                });
    
                // Sort filtered data in descending order
                const sortedData = [...filteredData].sort((a, b) => 
                    new Date(b.date).getTime() - new Date(a.date).getTime()
                );
    
                setUserGrowthData(sortedData);
    
                const currentMonth = sortedData[0];
                const previousMonth = sortedData[1];
    
                if (currentMonth && previousMonth) {
                    const growthRate = ((currentMonth.count - previousMonth.count) / previousMonth.count) * 100;
    
                    setAnalyticsData(prev => ({
                        ...prev,
                        growthRate: parseFloat(growthRate.toFixed(2))
                    }));
                }
            }
        } catch (error) {
            console.error('Error fetching user growth data:', error);
        } finally {
            setLoadingGrowth(false);
            setLoadingAnalytics(false);
        }
    }, [timeframe, userService, country]);
    

    const fetchDemographicsData = useCallback(async () => {
        setLoadingDemographics(true);
        try {
            const response = await userService.getUserDemographics({country});
            setDemographicsData(response.data);
        } catch (error) {
            console.error('Error fetching demographics data:', error);
        } finally {
            setLoadingDemographics(false);
        }
    }, [userService, country]);

    const fetchTopEarners = useCallback(async () => {
        setLoadingTopEarners(true);
        try {
            const response = await userService.getTopEarners();
            setTopEarners({
                balance: response.data.topEarnersByBalance,
                payouts: response.data.topEarnersByPayouts,
                rewards: response.data.topEarnersByRewards
            });
            setAnalyticsData(prev => ({
                ...prev,
                totalEarnings: parseFloat(response.data.overallStats.totalPayoutsAmount.toFixed(2))
            }));
            
        } catch (error) {
            console.error('Error fetching top earners:', error);
        } finally {
            setLoadingTopEarners(false);
        }
    }, [userService]);

    useEffect(() => {
        fetchUserGrowthData(); // Fetch user growth data
        fetchDemographicsData(); // Fetch demographics data
        fetchTopEarners(); // Fetch top earners
        fetchUserParticipationData(); // Fetch user participation data
        fetchYearlyMonthlyData(); // Fetch yearly/monthly data
    }, [fetchUserGrowthData, fetchDemographicsData, fetchTopEarners, fetchUserParticipationData, timeframe, country, fetchYearlyMonthlyData]);

    const countryOptions = Object.entries(countries).map(([code, details]) => ({
        code,
        name: details.name
    }));

    const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCountry(e.target.value);
    };

    const handleTimeframeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setTimeframe(e.target.value as 'month' | 'year');
    };

    const statsCards = useMemo(() => [
        { 
            title: 'Total Users', 
            value: loadingDemographics ? 'Loading...' : (demographicsData?.totalUsers || 0), 
            change: '+12%', 
            icon: <FaUsers size={24} />, 
            color: '#2a919d' 
        },
        { 
            title: 'Growth Rate', 
            value: loadingAnalytics ? 'Loading...' : `${Math.abs(analyticsData.growthRate).toFixed(2)}%`, 
            change: loadingAnalytics ? 'Loading...' : `${analyticsData.growthRate >= 0 ? '+' : '-'}${Math.abs(analyticsData.growthRate).toFixed(2)}%`,
            icon: <FaChartLine size={24} />, 
            color: analyticsData.growthRate >= 0 ? '#2a919d' : '#ef4444' 
        },
        { 
            title: 'Total Payouts', 
            value: `$${analyticsData.totalEarnings.toLocaleString()}`, 
            change: '+', 
            icon: <FaMoneyBillWave size={24} />, 
            color: '#2a919d' 
        },
        { 
            title: 'Completion Rate', 
            value: loadingParticipation ? 'Loading...' : `${analyticsData.completionRate.toFixed(2)}%`, 
            change: loadingParticipation 
                ? 'Loading...' 
                : `${analyticsData.completionRateChange >= 0 ? '+' : ''}${analyticsData.completionRateChange}%`,
            icon: <FaCheckCircle size={24} />, 
            color: analyticsData.completionRateChange >= 0 ? '#2a919d' : '#ef4444'
        },
    ], [demographicsData, loadingDemographics, analyticsData, loadingAnalytics, loadingParticipation]);

    return (
        <DashboardLayout>
            <div className="min-h-screen bg-gray-50 p-6 lg:p-8">
                <div className="mb-8">
                    <h1 className="text-[28px] font-bold text-[#3E3F66]">Analytics Dashboard</h1>
                    <p className="text-gray-600 mt-2">Track platform performance and user engagement</p>
                </div>

                {/* Global Filter Section */}
                <div className="bg-white p-4 mb-8 rounded-lg shadow-sm flex gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Country</label>
                        <select 
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#2a919d] focus:border-[#2a919d] sm:text-sm"
                            value={country}
                            onChange={handleCountryChange}
                        >
                            <option value="">All Countries</option>
                            {countryOptions.map((country) => (
                                <option key={country.code} value={country.code}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Timeframe</label>
                        <select 
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#2a919d] focus:border-[#2a919d] sm:text-sm"
                            value={timeframe}
                            onChange={handleTimeframeChange}
                        >
                            <option value="month">Monthly</option>
                            <option value="year">Yearly</option>
                        </select>
                    </div>
                </div>

                {/* Quick Stats */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                    {statsCards.map((stat) => (
                        <div key={stat.title} 
                             className="bg-white rounded-lg shadow-sm p-6 transition-all duration-300 hover:shadow-md">
                            <div className="flex items-center justify-between mb-4">
                                <span style={{ color: stat.color }}>{stat.icon}</span>
                                <span className="text-sm font-medium" style={{ color: stat.color }}>{stat.change}</span>
                            </div>
                            <h3 className="text-gray-600 text-sm font-medium mb-1">{stat.title}</h3>
                            <span className="text-2xl font-bold text-[#3E3F66]">{stat.value}</span>
                        </div>
                    ))}
                </div>

                {/* Charts Section */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                    <div className="bg-white rounded-lg shadow-sm p-6">
                        <h2 className="text-xl font-semibold text-[#3E3F66]">Growth Trends</h2>
                        <div className="h-[300px]">
                            <UserGrowthChart loading={loadingGrowth} data={userGrowthData} />
                        </div>
                    </div>
                    <div className="bg-white rounded-lg shadow-sm p-6">
                        <h2 className="text-xl font-semibold text-[#3E3F66]">User Participation</h2>
                        <div className="h-[300px]">
                          <UserParticipationChart loading={loadingParticipation} data={userParticipationData} />
                        </div>
                    </div>

                    {/* Demographics */}
                        <UserDemographicsChart loading={loadingDemographics} data={demographicsData as any} />
                         {/* Top Earners Table */}
                <div className="bg-white rounded-lg shadow-sm p-6 md:col-span-2 col-span-1">
                    <h2 className="text-xl font-semibold text-[#3E3F66] mb-6">Top Earners by Balance</h2>
                     <TopEarnersTable loading={loadingTopEarners} data={topEarners.balance || []} type="balance" />
                </div>
                <div className="bg-white rounded-lg shadow-sm p-6 md:col-span-2 col-span-1">
                    <h2 className="text-xl font-semibold text-[#3E3F66] mb-6">Top Earners by Payouts</h2>
                     <TopEarnersTable loading={loadingTopEarners} data={topEarners.payouts || []} type="payouts" />
                </div>
                <div className="bg-white rounded-lg shadow-sm p-6 md:col-span-2 col-span-1">
                    <h2 className="text-xl font-semibold text-[#3E3F66] mb-6">Top Earners by Rewards</h2>
                        <TopEarnersTable loading={loadingTopEarners} data={topEarners.rewards || []} type="rewards" />
                </div>
                     <div className="col-span-2">
                     <UserGrowthComparisonChart loading={loadingYearlyMonthly} data={yearlyMonthlyData || []} />
                     </div>
                </div>

               
            </div>
        </DashboardLayout>
    );
}

export default ManagerAnalytics;
