import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LoadingOverlay from "../../../Components/LoadingOverlay";
import { FiDownload, FiUpload } from "react-icons/fi";
import * as XLSX from "xlsx";
import DashboardLayout from "../../../Layouts/PageLayouts/DashboardLayout";
import CintService from "../../../Api/Routes/Cint/CintService";
import { useLanguage } from "../../../Hooks/useLanguage";
import ApprovalModal from "../../../Components/Modals/ApprovalModal";

interface CintReward {
  id: number;
  projectId: number;
  personId: number;
  amount: number;
  currency: string;
  status: "PENDING" | "SUCCESSFUL" | "FAILED";  // Changed from REJECTED to FAILED
  uuid: string;
  createdAt: string;
  projectName?: string;
}

function CintRewards() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { language } = useLanguage();

  const [rewards, setRewards] = useState<CintReward[]>(
    location.state?.rewards || []
  );
  const [processing, setProcessing] = useState<string[]>([]);
  const [selectedRewards, setSelectedRewards] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false)
  
  const [confirmModal, setConfirmModal] = useState<{
    isOpen: boolean;
    action: "APPROVED" | "FAILED" | null;  // Changed from REJECTED to FAILED
    uuid: string | null;
    projectCode: string | null;
  }>({
    isOpen: false,
    action: null,
    uuid: null,
    projectCode: null,
  });

  const [filter, setFilter] = useState({
    status: searchParams.get("status") || "all",
    projectId: searchParams.get("projectId") || "all",
  });

  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState<"ids" | "other" | null>(null);

  const cintService = new CintService();

  // Redirect if no rewards data
  React.useEffect(() => {
    if (!location.state?.rewards) {
      navigate(`/${language}/admin/cint/dashboard`);
    }
  }, [location.state, navigate, language]);

  const filteredRewards = rewards.filter((reward) => {
    if (filter.status !== "all" && reward.status !== filter.status)
      return false;
    if (
      filter.projectId !== "all" &&
      reward.projectId.toString() !== filter.projectId
    )
      return false;
    return true;
  });

  const handleProcessRewardClick = (
    uuid: string,
    action: "APPROVED" | "FAILED",
    projectCode: string
  ) => {
    setConfirmModal({
      isOpen: true,
      action,
      uuid,
      projectCode,
    });
  };

  const handleConfirmProcess = async () => {
    if (!confirmModal.action || !confirmModal.uuid || !confirmModal.projectCode)
      return;

    await handleProcessReward(
      confirmModal.uuid,
      confirmModal.action,
      confirmModal.projectCode
    );
    setConfirmModal({
      isOpen: false,
      action: null,
      uuid: null,
      projectCode: null,
    });
  };

  const handleProcessReward = async (
    uuid: string,
    newStatus: "APPROVED" | "FAILED",
    projectCode: string
  ) => {
    const newCode = "cint_" + projectCode + "_ng";
    try {
      setApproveLoading(true)
      setProcessing((prev) => [...prev, uuid]);
      await cintService.processReward(newStatus, [uuid], newCode);

      setRewards((prevRewards:any) =>
        prevRewards.map((reward:any) =>
          reward.uuid === uuid ? { ...reward, status: newStatus } : reward
        )
      );

      navigate(`/${language}/admin/cint`);
      
    } catch (error) {
      setApproveLoading(false)
      console.error("Failed to process reward:", error);
    } finally {
      setApproveLoading(false)
      setProcessing((prev) => prev.filter((id) => id !== uuid));
    }
  };

  const handleProcessSelected = async (newStatus: "APPROVED" | "FAILED") => {
    if (!selectedRewards.length) return;

    try {
      setProcessing(selectedRewards);
      const rewardsToProcess = selectedRewards.map(uuid => ({
        status: newStatus,
        uuid,
        projectCode: `cint_${rewards.find(r => r.uuid === uuid)?.projectId}_ng`
      }));
      await cintService.processBulkRewards(rewardsToProcess);

      setRewards((prevRewards:any) =>
        prevRewards.map((reward:any) =>
          selectedRewards.includes(reward.uuid)
            ? { ...reward, status: newStatus }
            : reward
        )
      );

      setSelectedRewards([]);
    } catch (error) {
      console.error("Failed to process rewards:", error);
    } finally {
      setProcessing([]);
    }
  };

  const handleExport = () => {
    const data = filteredRewards.map((reward) => ({
      "Project ID": reward.projectId,
      "Project Name": reward.projectName,
      "Person ID": reward.personId,
      Amount: reward.amount,
      Currency: reward.currency,
      Status: reward.status,
      UUID: reward.uuid,
      "Created At": new Date(reward.createdAt).toLocaleString(),
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Rewards");
    XLSX.writeFile(wb, "cint-rewards.xlsx");
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, type: "ids" | "other") => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData: any = XLSX.utils.sheet_to_json(worksheet);

        if (!jsonData[0]?.UUID && !jsonData[0]?.ID) {
          alert("The file must contain either a 'UUID' or 'ID' column.");
          return;
        }

        const uuids = jsonData.map((row: any) => row.UUID || row.ID);
        setSelectedRewards(uuids);
        setFileUploaded(true);
        setFileName(file.name);
        setFileType(type);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleRemoveFile = () => {
    setSelectedRewards([]);
    setFileUploaded(false);
    setFileName("");
    setFileType(null);
    const fileInputIds = document.getElementById("uploadFileIds") as HTMLInputElement;
    const fileInputOther = document.getElementById("uploadFileOther") as HTMLInputElement;
    if (fileInputIds) fileInputIds.value = "";
    if (fileInputOther) fileInputOther.value = "";
  };

  return (
    <DashboardLayout heading="Cint Rewards">
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-zinc-900">Cint Rewards</h1>
            <p className="text-sm text-zinc-500">
              Manage and process rewards for Cint surveys
            </p>
          </div>
          <div className="flex space-x-3">
            <div className="flex items-center space-x-2">
              <label className="relative cursor-pointer">
                <input
                  type="file"
                  id="uploadFileIds"
                  accept=".xlsx,.xls,.csv"
                  onChange={(e) => handleFileUpload(e, "ids")}
                  className="hidden"
                />
                <div className="flex items-center space-x-2 px-4 py-2 text-sm font-medium text-zinc-700 bg-white border border-zinc-300 rounded-lg hover:bg-zinc-50">
                  <FiUpload className="w-4 h-4" />
                  <span>Upload IDs</span>
                </div>
              </label>
              <label className="relative cursor-pointer">
                <input
                  type="file"
                  id="uploadFileOther"
                  accept=".xlsx,.xls,.csv"
                  onChange={(e) => handleFileUpload(e, "other")}
                  className="hidden"
                />
                <div className="flex items-center space-x-2 px-4 py-2 text-sm font-medium text-zinc-700 bg-white border border-zinc-300 rounded-lg hover:bg-zinc-50">
                  <FiUpload className="w-4 h-4" />
                  <span>Upload Other</span>
                </div>
              </label>
              {fileUploaded && (
                <div className="flex items-center space-x-2">
                  <span className="text-sm text-zinc-600">
                    {fileName} ({fileType === "ids" ? "IDs" : "Other"})
                  </span>
                  <button
                    onClick={handleRemoveFile}
                    className="text-xs text-red-600 hover:text-red-700"
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>

            <button
              onClick={handleExport}
              className="flex items-center space-x-2 px-4 py-2 text-sm font-medium text-zinc-700 bg-white border border-zinc-300 rounded-lg hover:bg-zinc-50"
            >
              <FiDownload className="w-4 h-4" />
              <span>Export</span>
            </button>
            {selectedRewards.length > 0 && (
              <>
                <button
                  onClick={() => handleProcessSelected("APPROVED")}
                  disabled={processing.length > 0}
                  className={`flex items-center space-x-2 px-4 py-2 text-sm font-medium text-white rounded-lg
                    ${
                      processing.length > 0
                        ? "bg-zinc-300 cursor-not-allowed"
                        : "bg-green-600 hover:bg-green-700"
                    }`}
                >
                  <span>Approve Selected ({selectedRewards.length})</span>
                </button>
                <button
                  onClick={() => handleProcessSelected("FAILED")}
                  disabled={processing.length > 0}
                  className={`flex items-center space-x-2 px-4 py-2 text-sm font-medium text-white rounded-lg
                    ${
                      processing.length > 0
                        ? "bg-zinc-300 cursor-not-allowed"
                        : "bg-red-600 hover:bg-red-700"
                    }`}
                >
                  <span>Reject Selected ({selectedRewards.length})</span>
                </button>
              </>
            )}
          </div>
        </div>

        <div className="bg-white rounded-lg border border-zinc-200 overflow-hidden">
          {loading ? (
            <LoadingOverlay />
          ) : (
            <table className="min-w-full divide-y divide-zinc-200">
              <thead className="bg-zinc-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">
                    <input
                      type="checkbox"
                      checked={selectedRewards.length === rewards.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedRewards(rewards.map((r) => r.uuid));
                        } else {
                          setSelectedRewards([]);
                        }
                      }}
                      className="rounded border-zinc-300 text-[#29A1AF] focus:ring-[#29A1AF]"
                    />
                  </th>
                
                  <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">
                    UUID
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider">
                    Created At
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-zinc-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-zinc-200">
                {filteredRewards.map((reward) => (
                  <tr key={reward.uuid} className="hover:bg-zinc-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="checkbox"
                        checked={selectedRewards.includes(reward.uuid)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedRewards((prev) => [
                              ...prev,
                              reward.uuid,
                            ]);
                          } else {
                            setSelectedRewards((prev) =>
                              prev.filter((id) => id !== reward.uuid)
                            );
                          }
                        }}
                        className="rounded border-zinc-300 text-[#29A1AF] focus:ring-[#29A1AF]"
                      />
                    </td>
                 
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-500">
                      {reward.uuid}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-sm font-medium text-zinc-900">
                        ${reward.amount.toFixed(2)}
                      </span>
                      <span className="text-xs text-zinc-500 ml-1">
                        {reward.currency}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 py-1 text-xs font-medium rounded-full
                        ${
                          reward.status === "PENDING"
                            ? "bg-yellow-100 text-yellow-800"
                            : reward.status === "SUCCESSFUL"
                            ? "bg-green-100 text-green-800"
                            : "bg-red-100 text-red-800"
                        }`}
                      >
                        {reward.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-500">
                      {new Date(reward.createdAt).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right">
                      {reward.status === "PENDING" && (
                        <div className="flex justify-end space-x-2">
                          <button
                            onClick={() =>
                              handleProcessRewardClick(
                                reward.uuid,
                                "APPROVED",
                                reward.projectId.toString()
                              )
                            }
                            disabled={processing.includes(reward.uuid)}
                            className={`text-xs font-medium rounded-md px-3 py-1
                            ${
                              processing.includes(reward.uuid)
                                ? "bg-zinc-100 text-zinc-400 cursor-not-allowed"
                                : "bg-green-100 text-green-800 hover:bg-green-200"
                              }`} 
                          >
                            {processing.includes(reward.uuid)
                              ? "Processing..."
                              : "Approve"}
                          </button>
                          <button
                            onClick={() =>
                              handleProcessRewardClick(
                                reward.uuid,
                                "FAILED",
                                reward.projectId.toString()
                              )
                            }
                            disabled={processing.includes(reward.uuid)}
                            className={`text-xs font-medium rounded-md px-3 py-1
                              ${
                                processing.includes(reward.uuid)
                                  ? "bg-zinc-100 text-zinc-400 cursor-not-allowed"
                                  : "bg-red-100 text-red-800 hover:bg-red-200"
                              }`}
                          >
                            {processing.includes(reward.uuid)
                              ? "Processing..."
                              : "Reject"}
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {confirmModal.isOpen && (
        <ApprovalModal
          onClose={() =>
            setConfirmModal({
              isOpen: false,
              action: null,
              uuid: null,
              projectCode: null,
            })
          }
          onConfirm={handleConfirmProcess}
          loading={approveLoading}
          action={confirmModal.action}
          uuid={confirmModal.uuid}
          projectCode={confirmModal.projectCode}
        />
      )}
    </DashboardLayout>
  );
}

export default CintRewards;
