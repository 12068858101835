import { ReactNode, useState } from "react";
import "../../Assets/Styles/DashboardLayout.css";
import Sidebar from "../SideBar/SideBar";
import TopBar from "../TopBar/TopBar";
import FooterV2 from "../Footer/FooterV2";
import { Helmet } from "react-helmet";
import useUserActivity from "../../Hooks/useUserActivity";
import CustomLoading from "../../Components/ui/CustomLoading/CustomLoading";

type Props = { children: ReactNode; heading?: string, isLoading?:boolean };

function DashboardLayout({ children, heading, isLoading }: Props) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  useUserActivity();
  return (
    <div className="dashboard relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{heading ?? "Dashboard"} | Afrisight</title>
      </Helmet>
      <div className="dashboard_sidebar">
        <Sidebar setIsNavOpen={setIsNavOpen} />
      </div>
      <div className="dasboard_container z-40">
        <div className="dashboard_containerNavbar">
          <TopBar isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
        </div>
        <div className="md:pl-[260px] pl-0 w-full mx-auto py-8">
          {isLoading ? <CustomLoading/> :
           <div className="px-4 md:px-8 w-full flex flex-col">
              {children}
          </div> }
          
        </div>
        <div className="dashboard_containerFooter">
          <FooterV2 />
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
