import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import LoadingOverlay from "../Components/LoadingOverlay";
import CustomAlert from "../Components/CustomAlert";
import CryptoJS from "crypto-js";
import '../Assets/Styles/SurveyResultPage.css';
import screenOutImage from '../Assets/Images/screenout.png';
import completeImage from '../Assets/Images/complete.png';
import { useTranslation } from "react-i18next";
import { useLanguage } from "../Hooks/useLanguage";

// Add persistent logging utility
const logWithPersistence = (type: 'info' | 'error' | 'warn', message: string, data?: any) => {
    const timestamp = new Date().toISOString();
    const logEntry = {
        timestamp,
        type,
        message,
        data: data || null
    };
    
    // Store in sessionStorage for persistence
    const existingLogs = sessionStorage.getItem('cint_result_logs');
    const logs = existingLogs ? JSON.parse(existingLogs) : [];
    logs.push(logEntry);
    sessionStorage.setItem('cint_result_logs', JSON.stringify(logs));
    
    // Also log to console
    switch (type) {
        case 'error':
            console.error(`[${timestamp}] ${message}`, data || '');
            break;
        case 'warn':
            console.warn(`[${timestamp}] ${message}`, data || '');
            break;
        default:
            console.log(`[${timestamp}] ${message}`, data || '');
    }
};

const SurveyResultCint: React.FC = () => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const HOME = `/${language}/home`;
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState<{ type: 'success' | 'error' | 'warning' | 'info'; message: string } | null>(null);
    const [status, setStatus] = useState<string | null>(null);
    const [projectCode, setProjectCode] = useState<string | null>(null);

    // Function to view logs (for debugging)
    const viewLogs = () => {
        const logs = sessionStorage.getItem('cint_result_logs');
        if (logs) {
            console.log('CINT RESULT LOGS:', JSON.parse(logs));
        } else {
            console.log('No CINT result logs found');
        }
    };

    const decryptData = useCallback((encryptedData: string) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_CRYPTO_KEY || 'default_key');
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            throw new Error(t('surveyResults.invalid_encryption'));
        }
    }, [t]);

    useEffect(() => {
        const processSurveyResult = async () => {
            setLoading(true);
            const queryParams = new URLSearchParams(location.search);
            const data = queryParams.get('data');

            if (data) {
                try {
                    logWithPersistence('info', 'Processing survey result', { encryptedData: data });
                    const decryptedData = decryptData(data);
                    let { projectCode, status } = decryptedData;
                    
                    logWithPersistence('info', 'Decrypted survey result data', { projectCode, status });
                    
                    setProjectCode(projectCode);

                    // Normalize status
                    if (status === 'GEO_LOCKED') {
                        status = 'TARGET_UNSUITABLE';
                    }
                    
                    // Set status in uppercase for consistency
                    const normalizedStatus = status.toUpperCase();
                    setStatus(normalizedStatus);
                    
                    // No API call needed - status would have been updated already
                    setLoading(false);
                } catch (error: any) {
                    logWithPersistence('error', 'Error processing survey result', { error: error.message });
                    setAlert({ type: 'error', message: error.message || t('surveyResults.invalid_or_expired_link') });
                    setLoading(false);
                    setTimeout(() => {
                        navigate(HOME);
                    }, 5000);
                }
            } else {
                logWithPersistence('error', 'Missing data parameter in URL');
                setAlert({ type: 'warning', message: t('surveyResults.status_or_project_code_missing') });
                setLoading(false);
                setTimeout(() => {
                    navigate(HOME);
                }, 5000);
            }
        };

        processSurveyResult();
        
        // Add cleanup to view logs when component unmounts (for debugging)
        return () => {
            viewLogs();
        };
    }, [location.search, decryptData, HOME, navigate, t]);

    const getTitle = () => {
        switch (status) {
            case 'COMPLETED':
                return t('surveyResults.survey_completed_title');
            case 'SCREEN_OUT':
            case 'DISQUALIFIED':
                return t('surveyResults.survey_screen_out_title');
            case 'QUOTA_FULL':
                return t('surveyResults.survey_quota_full_title');
            case 'TARGET_UNSUITABLE':
                return t('surveyResults.survey_target_unsuitable_title');
            case 'CLOSED':
                return t('surveyResults.survey_closed_title');
            case 'BOT_DETECTED':
                return t('surveyResults.survey_bot_detected_title');
            case 'GEO_LOCKED':
                return t('surveyResults.survey_geo_locked_title');
            default:
                return t('surveyResults.survey_unknown_status_title');
        }
    };

    const getMessage = () => {
        switch (status) {
            case 'COMPLETED':
                return (
                    <>
                        <p>{t('surveyResults.survey_completed_message_1')}</p>
                        <p>{t('surveyResults.survey_completed_message_2')}</p>
                        <p>{t('surveyResults.survey_completed_message_3')}</p>
                    </>
                );
            case 'SCREEN_OUT':
            case 'DISQUALIFIED':
                return (
                    <>
                        <p>{t('surveyResults.survey_screen_out_message_1')}</p>
                        <p>{t('surveyResults.survey_screen_out_message_2')}</p>
                    </>
                );
            case 'QUOTA_FULL':
                return (
                    <>
                        <p>{t('surveyResults.survey_quota_full_message')}</p>
                    </>
                );
            case 'CLOSED':
                return (
                    <>
                        <p>{t('surveyResults.survey_closed_message_1')}</p>
                        <p>{t('surveyResults.survey_closed_message_2')}</p>
                    </>
                );
            case 'BOT_DETECTED':
                return (
                    <>
                        <p>{t('surveyResults.survey_bot_detected_message')}</p>
                    </>
                );
            case 'GEO_LOCKED':
            case 'TARGET_UNSUITABLE':
                return (
                    <>
                        <p>{t('surveyResults.survey_target_unsuitable_message_1')}</p>
                        <p>{t('surveyResults.survey_target_unsuitable_message_2')}</p>
                    </>
                );
            default:
                return (
                    <>
                        <p>{t('surveyResults.survey_unknown_status_message')}</p>
                    </>
                );
        }
    };

    const getImage = () => {
        return status === 'COMPLETED' ? completeImage : screenOutImage;
    };

    const getHelmetTitle = () => {
        switch (status) {
            case 'COMPLETED':
                return t('surveyResults.survey_completed_helmet_title');
            case 'SCREEN_OUT':
            case 'DISQUALIFIED':
                return t('surveyResults.survey_screen_out_helmet_title');
            case 'QUOTA_FULL':
                return t('surveyResults.survey_quota_full_helmet_title');
            case 'TARGET_UNSUITABLE':
                return t('surveyResults.survey_target_unsuitable_helmet_title');
            case 'CLOSED':
                return t('surveyResults.survey_closed_helmet_title');
            case 'BOT_DETECTED':
                return t('surveyResults.survey_bot_detected_helmet_title');
            case 'GEO_LOCKED':
                return t('surveyResults.survey_geo_locked_helmet_title');
            default:
                return t('surveyResults.survey_unknown_status_helmet_title');
        }
    };

    // Add a hidden button to view logs (only visible in development)
    const isDevelopment = process.env.NODE_ENV === 'development';

    return (
        <div className="main-survey-result">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{getHelmetTitle()}</title>
            </Helmet>
            {loading && <LoadingOverlay />}
            {alert && <CustomAlert type={alert.type} message={alert.message} onClose={() => setAlert(null)} />}
            {isDevelopment && (
                <button 
                    onClick={viewLogs} 
                    style={{ 
                        position: 'fixed', 
                        bottom: '10px', 
                        right: '10px', 
                        zIndex: 9999,
                        padding: '5px',
                        fontSize: '10px',
                        opacity: 0.7
                    }}
                >
                    View Logs
                </button>
            )}
            {!loading && status && (
                <div className="survey-result-container">
                    <img src={getImage()} alt={status || 'RESULT_IMG'} className="survey-result-image" />
                    <div className="survey-result-content">
                        <h1 className="survey-result-title">{getTitle()}</h1>
                        <div className="survey-result-text">{getMessage()}</div>
                        <a href={HOME} className="survey-result-link">{t('surveyResults.survey_go_back_home')}</a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SurveyResultCint;
