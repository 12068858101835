import React from 'react';
import '../../Assets/Styles/Header.css';
import logo from '../../Assets/Images/logo.svg'
import { Link } from 'react-router-dom';
import {useLanguage} from "../../Hooks/useLanguage";

const HeaderComponent: React.FC = () => {
    const { language } = useLanguage();
    const HOME = `/${language}/`;
    return (
        <header className="header-main">
            <div className="header-content">
                <Link to={HOME}><img src={logo} alt="AfriSight Logo" className="logo" /></Link>
            </div>
        </header>
    );
};

export default HeaderComponent;
